var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_product") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 10 } },
            [
              _c("InputSearch", {
                on: { "input-search": _vm.reponseSearchInput },
                model: {
                  value: _vm.vmSearch,
                  callback: function($$v) {
                    _vm.vmSearch = $$v
                  },
                  expression: "vmSearch"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 8 } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.resetFilter()
                    }
                  }
                },
                [
                  _c("a-icon", {
                    staticClass: "icon-reload",
                    attrs: { type: "reload" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 6, align: "end" } },
            [
              _vm.hasPrivilegeCreateProduct
                ? _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.goToCreate }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dataList.data,
                    pagination: false,
                    scroll: { x: "calc(1000px - 50%)" },
                    "row-class-name": _vm.rowClassName
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "code",
                      attrs: { "data-index": "code" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(code) {
                            return [_vm._v(" " + _vm._s(code || "-") + " ")]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "name",
                      attrs: { "data-index": "name" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(name) {
                            return [_vm._v(" " + _vm._s(name || "-") + " ")]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "categoryName",
                      attrs: { "data-index": "categoryName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(categoryName) {
                            return [
                              _vm._v(" " + _vm._s(categoryName || "-") + " ")
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_category_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "type",
                      attrs: { "data-index": "type" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(type) {
                            return [_vm._v(" " + _vm._s(type || "-") + " ")]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_type")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "brand",
                      attrs: { "data-index": "brand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(brand) {
                            return [_vm._v(" " + _vm._s(brand || "-") + " ")]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_brand")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "status",
                      attrs: {
                        "data-index": "status",
                        fixed: "right",
                        width: 100,
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(status) {
                            return [
                              _c("a-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: status,
                                    expression: "status"
                                  }
                                ],
                                staticStyle: { color: "#52c41a" },
                                attrs: { type: "check-circle" }
                              }),
                              _c("a-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !status,
                                    expression: "!status"
                                  }
                                ],
                                staticStyle: { color: "#f5222d" },
                                attrs: { type: "close" }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_status")))
                      ])
                    ]
                  ),
                  _vm.hasPrivilegeCreateProduct
                    ? _c(
                        "a-table-column",
                        {
                          key: "action",
                          attrs: {
                            fixed: "right",
                            width: 100,
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(text, record) {
                                  return _c(
                                    "a-button",
                                    {
                                      attrs: { type: "link" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToUpdate(record.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_update")) + " "
                                      )
                                    ]
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            2174999334
                          )
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [16, 16],
            type: "flex",
            justify: "space-between",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dataList.totalElements,
                  "page-size-set": _vm.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { align: "end" } },
            [
              _c("a-tag", { attrs: { color: "grey" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dataList.totalElements) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }