






























































































































































import {
  ResponseListProduct,
  ResponseProduct,
} from "@/models/interface-v2/product.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { productService } from "@/services-v2/product.service";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SetupProduct",
  data() {
    return {
      limit: 10 as number,
      page: 0 as number,
      sort: "" as string,
      dataList: {} as ResponseListProduct,
      loading: {
        table: false,
      },
      vmSearch: "",
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivilege: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreateProduct(): boolean {
      return this.getUserPrivilege.find(
        (x) => x.key === "product" && x.privilege.create
      );
    },
  },
  mounted() {
    this.getListOfProduct();
  },
  methods: {
    rowClassName(record: ResponseProduct): string {
      return record.parent ? "is-parent" : "";
    },
    goToUpdate(id: string): void {
      this.$router.push({
        name: "logistic.setupproduct.update",
        params: { id },
      });
    },
    goToCreate(): void {
      this.$router.push({
        name: "logistic.setupproduct.create",
      });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 0;
      this.getListOfProduct();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page - 1;
      this.getListOfProduct();
    },
    reponseSearchInput(value): void {
      if (value)
        this.search = `search=name~*${value}*_OR_code~*${value}*_OR_category.name~*${value}*_OR_brand~*${value}*`;
      else this.search = "";
      this.page = 0;
      this.getListOfProduct();
    },
    resetFilter(): void {
      this.search = "";
      this.vmSearch = "";
      this.getListOfProduct();
    },
    getListOfProduct(): void {
      let params = {
        limit: this.limit,
        page: this.page,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      this.loading.table = true;
      productService
        .listProduct(params)
        .then((res: ResponseListProduct) => {
          res.data.forEach((item, index) => (item["key"] = index));
          this.dataList = res;
        })
        .catch(() =>
          this.$message.error(this.$t("notif_process_fail").toString())
        )
        .finally(() => (this.loading.table = false));
    },
  },
});
